import { default as _91id_93oN5wJ6Xp5JMeta } from "/home/sila-sp/node_menu/pages/domain/about/[id].vue?macro=true";
import { default as cartiH1NN7tz0FMeta } from "/home/sila-sp/node_menu/pages/domain/cart.vue?macro=true";
import { default as categoriesxe9YlUpFupMeta } from "/home/sila-sp/node_menu/pages/domain/categories.vue?macro=true";
import { default as indexACrqIkt1SxMeta } from "/home/sila-sp/node_menu/pages/domain/index.vue?macro=true";
import { default as _91id_93vmRjTpqfbMMeta } from "/home/sila-sp/node_menu/pages/domain/materials/[id].vue?macro=true";
import { default as index3hwfg0aR1OMeta } from "/home/sila-sp/node_menu/pages/domain/materials/index.vue?macro=true";
import { default as offersiRzZwTymyYMeta } from "/home/sila-sp/node_menu/pages/domain/offers.vue?macro=true";
import { default as about_45us00cEztlcfrMeta } from "/home/sila-sp/node_menu/pages/platform/about-us.vue?macro=true";
import { default as email_45reset_45passwordrCOwC0aKekMeta } from "/home/sila-sp/node_menu/pages/platform/auth/index/email-reset-password.vue?macro=true";
import { default as forgotmiJLLXDxZJMeta } from "/home/sila-sp/node_menu/pages/platform/auth/index/forgot.vue?macro=true";
import { default as loginnLhfSOmzpQMeta } from "/home/sila-sp/node_menu/pages/platform/auth/index/login.vue?macro=true";
import { default as phone_45reset_45passwordOw7QbEOvIlMeta } from "/home/sila-sp/node_menu/pages/platform/auth/index/phone-reset-password.vue?macro=true";
import { default as registerWYQX0alTmsMeta } from "/home/sila-sp/node_menu/pages/platform/auth/index/register.vue?macro=true";
import { default as indexQK6xxyvE8sMeta } from "/home/sila-sp/node_menu/pages/platform/auth/index.vue?macro=true";
import { default as _providerwTHrV3azOlMeta } from "/home/sila-sp/node_menu/pages/platform/auth/social/_provider.vue?macro=true";
import { default as verify_45emailEnYVuER4AwMeta } from "/home/sila-sp/node_menu/pages/platform/auth/verify-email.vue?macro=true";
import { default as cartqZ7sl0usB1Meta } from "/home/sila-sp/node_menu/pages/platform/cart.vue?macro=true";
import { default as indexXaTm1IxW33Meta } from "/home/sila-sp/node_menu/pages/platform/delivering/index.vue?macro=true";
import { default as _91id_931CaWYCw5jLMeta } from "/home/sila-sp/node_menu/pages/platform/delivering/materials/[id].vue?macro=true";
import { default as _91id_93jnXKz3Ln6RMeta } from "/home/sila-sp/node_menu/pages/platform/delivering/stores/[id].vue?macro=true";
import { default as _91id_93whNpld9yO4Meta } from "/home/sila-sp/node_menu/pages/platform/delivering/storeTypes/[id].vue?macro=true";
import { default as indexaycy8FzTNYMeta } from "/home/sila-sp/node_menu/pages/platform/index.vue?macro=true";
import { default as _91id_93YLmF9xCArrMeta } from "/home/sila-sp/node_menu/pages/platform/material/[id].vue?macro=true";
import { default as _91id_93msMPNiWVVYMeta } from "/home/sila-sp/node_menu/pages/platform/materials/[id].vue?macro=true";
import { default as indexOVo8UUdFBTMeta } from "/home/sila-sp/node_menu/pages/platform/materials/index.vue?macro=true";
import { default as editXbL7XJneByMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/edit.vue?macro=true";
import { default as index3OGJ8DufsPMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/index.vue?macro=true";
import { default as invite_45friendsgK3GoNMftrMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/invite-friends.vue?macro=true";
import { default as money_45movementsXFnnFsjn4BMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/money-movements.vue?macro=true";
import { default as notifications0aoYi0oXBhMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/notifications.vue?macro=true";
import { default as _91id_93augAEhB4HuMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/orders/[id].vue?macro=true";
import { default as indexPeRdSZj97oMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/orders/index.vue?macro=true";
import { default as placesXSwDbbg9TDMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/places.vue?macro=true";
import { default as wishlistBZJrGoecSjMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index/wishlist.vue?macro=true";
import { default as index1m5cHcuL2IMeta } from "/home/sila-sp/node_menu/pages/platform/my-account/index.vue?macro=true";
import { default as offers3JF6Z9Jb1RMeta } from "/home/sila-sp/node_menu/pages/platform/offers.vue?macro=true";
import { default as privacyqOU39uIgWzMeta } from "/home/sila-sp/node_menu/pages/platform/privacy.vue?macro=true";
import { default as store_45typesjNv9crpfbWMeta } from "/home/sila-sp/node_menu/pages/platform/store-types.vue?macro=true";
import { default as _91id_93u9P2W8NZmXMeta } from "/home/sila-sp/node_menu/pages/platform/stores/[id].vue?macro=true";
import { default as indexIbWfpKldiaMeta } from "/home/sila-sp/node_menu/pages/platform/stores/index.vue?macro=true";
import { default as successful_45purchase_45cartTX6ZFY1whCMeta } from "/home/sila-sp/node_menu/pages/platform/successful-purchase-cart.vue?macro=true";
import { default as termszCUYnZyHeGMeta } from "/home/sila-sp/node_menu/pages/platform/terms.vue?macro=true";
export default [
  {
    name: "domain-about-id___en",
    path: "/en/domain/about/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/domain/about/[id].vue")
  },
  {
    name: "domain-about-id___tr",
    path: "/tr/domain/about/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/domain/about/[id].vue")
  },
  {
    name: "domain-about-id___ar",
    path: "/domain/about/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/domain/about/[id].vue")
  },
  {
    name: "domain-cart___en",
    path: "/en/domain/cart",
    component: () => import("/home/sila-sp/node_menu/pages/domain/cart.vue")
  },
  {
    name: "domain-cart___tr",
    path: "/tr/domain/cart",
    component: () => import("/home/sila-sp/node_menu/pages/domain/cart.vue")
  },
  {
    name: "domain-cart___ar",
    path: "/domain/cart",
    component: () => import("/home/sila-sp/node_menu/pages/domain/cart.vue")
  },
  {
    name: "domain-categories___en",
    path: "/en/domain/categories",
    component: () => import("/home/sila-sp/node_menu/pages/domain/categories.vue")
  },
  {
    name: "domain-categories___tr",
    path: "/tr/domain/categories",
    component: () => import("/home/sila-sp/node_menu/pages/domain/categories.vue")
  },
  {
    name: "domain-categories___ar",
    path: "/domain/categories",
    component: () => import("/home/sila-sp/node_menu/pages/domain/categories.vue")
  },
  {
    name: "domain___en",
    path: "/en/domain",
    component: () => import("/home/sila-sp/node_menu/pages/domain/index.vue")
  },
  {
    name: "domain___tr",
    path: "/tr/domain",
    component: () => import("/home/sila-sp/node_menu/pages/domain/index.vue")
  },
  {
    name: "domain___ar",
    path: "/domain",
    component: () => import("/home/sila-sp/node_menu/pages/domain/index.vue")
  },
  {
    name: "domain-materials-id___en",
    path: "/en/domain/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/domain/materials/[id].vue")
  },
  {
    name: "domain-materials-id___tr",
    path: "/tr/domain/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/domain/materials/[id].vue")
  },
  {
    name: "domain-materials-id___ar",
    path: "/domain/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/domain/materials/[id].vue")
  },
  {
    name: "domain-materials___en",
    path: "/en/domain/materials",
    component: () => import("/home/sila-sp/node_menu/pages/domain/materials/index.vue")
  },
  {
    name: "domain-materials___tr",
    path: "/tr/domain/materials",
    component: () => import("/home/sila-sp/node_menu/pages/domain/materials/index.vue")
  },
  {
    name: "domain-materials___ar",
    path: "/domain/materials",
    component: () => import("/home/sila-sp/node_menu/pages/domain/materials/index.vue")
  },
  {
    name: "domain-offers___en",
    path: "/en/domain/offers",
    component: () => import("/home/sila-sp/node_menu/pages/domain/offers.vue")
  },
  {
    name: "domain-offers___tr",
    path: "/tr/domain/offers",
    component: () => import("/home/sila-sp/node_menu/pages/domain/offers.vue")
  },
  {
    name: "domain-offers___ar",
    path: "/domain/offers",
    component: () => import("/home/sila-sp/node_menu/pages/domain/offers.vue")
  },
  {
    name: "platform-about-us___en",
    path: "/en/platform/about-us",
    component: () => import("/home/sila-sp/node_menu/pages/platform/about-us.vue")
  },
  {
    name: "platform-about-us___tr",
    path: "/tr/platform/about-us",
    component: () => import("/home/sila-sp/node_menu/pages/platform/about-us.vue")
  },
  {
    name: "platform-about-us___ar",
    path: "/platform/about-us",
    component: () => import("/home/sila-sp/node_menu/pages/platform/about-us.vue")
  },
  {
    name: "platform-auth___en",
    path: "/en/platform/auth",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index.vue"),
    children: [
  {
    name: "platform-auth-index-email-reset-password___en",
    path: "email-reset-password",
    meta: email_45reset_45passwordrCOwC0aKekMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/email-reset-password.vue")
  },
  {
    name: "platform-auth-index-forgot___en",
    path: "forgot",
    meta: forgotmiJLLXDxZJMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/forgot.vue")
  },
  {
    name: "platform-auth-index-login___en",
    path: "login",
    meta: loginnLhfSOmzpQMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/login.vue")
  },
  {
    name: "platform-auth-index-phone-reset-password___en",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordOw7QbEOvIlMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/phone-reset-password.vue")
  },
  {
    name: "platform-auth-index-register___en",
    path: "register",
    meta: registerWYQX0alTmsMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/register.vue")
  }
]
  },
  {
    name: "platform-auth___tr",
    path: "/tr/platform/auth",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index.vue"),
    children: [
  {
    name: "platform-auth-index-email-reset-password___tr",
    path: "email-reset-password",
    meta: email_45reset_45passwordrCOwC0aKekMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/email-reset-password.vue")
  },
  {
    name: "platform-auth-index-forgot___tr",
    path: "forgot",
    meta: forgotmiJLLXDxZJMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/forgot.vue")
  },
  {
    name: "platform-auth-index-login___tr",
    path: "login",
    meta: loginnLhfSOmzpQMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/login.vue")
  },
  {
    name: "platform-auth-index-phone-reset-password___tr",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordOw7QbEOvIlMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/phone-reset-password.vue")
  },
  {
    name: "platform-auth-index-register___tr",
    path: "register",
    meta: registerWYQX0alTmsMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/register.vue")
  }
]
  },
  {
    name: "platform-auth___ar",
    path: "/platform/auth",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index.vue"),
    children: [
  {
    name: "platform-auth-index-email-reset-password___ar",
    path: "email-reset-password",
    meta: email_45reset_45passwordrCOwC0aKekMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/email-reset-password.vue")
  },
  {
    name: "platform-auth-index-forgot___ar",
    path: "forgot",
    meta: forgotmiJLLXDxZJMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/forgot.vue")
  },
  {
    name: "platform-auth-index-login___ar",
    path: "login",
    meta: loginnLhfSOmzpQMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/login.vue")
  },
  {
    name: "platform-auth-index-phone-reset-password___ar",
    path: "phone-reset-password",
    meta: phone_45reset_45passwordOw7QbEOvIlMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/phone-reset-password.vue")
  },
  {
    name: "platform-auth-index-register___ar",
    path: "register",
    meta: registerWYQX0alTmsMeta || {},
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/index/register.vue")
  }
]
  },
  {
    name: "platform-auth-social-_provider___en",
    path: "/en/platform/auth/social/_provider",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/social/_provider.vue")
  },
  {
    name: "platform-auth-social-_provider___tr",
    path: "/tr/platform/auth/social/_provider",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/social/_provider.vue")
  },
  {
    name: "platform-auth-social-_provider___ar",
    path: "/platform/auth/social/_provider",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/social/_provider.vue")
  },
  {
    name: "platform-auth-verify-email___en",
    path: "/en/platform/auth/verify-email",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/verify-email.vue")
  },
  {
    name: "platform-auth-verify-email___tr",
    path: "/tr/platform/auth/verify-email",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/verify-email.vue")
  },
  {
    name: "platform-auth-verify-email___ar",
    path: "/platform/auth/verify-email",
    component: () => import("/home/sila-sp/node_menu/pages/platform/auth/verify-email.vue")
  },
  {
    name: "platform-cart___en",
    path: "/en/platform/cart",
    component: () => import("/home/sila-sp/node_menu/pages/platform/cart.vue")
  },
  {
    name: "platform-cart___tr",
    path: "/tr/platform/cart",
    component: () => import("/home/sila-sp/node_menu/pages/platform/cart.vue")
  },
  {
    name: "platform-cart___ar",
    path: "/platform/cart",
    component: () => import("/home/sila-sp/node_menu/pages/platform/cart.vue")
  },
  {
    name: "platform-delivering___en",
    path: "/en/platform/delivering",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/index.vue")
  },
  {
    name: "platform-delivering___tr",
    path: "/tr/platform/delivering",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/index.vue")
  },
  {
    name: "platform-delivering___ar",
    path: "/platform/delivering",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/index.vue")
  },
  {
    name: "platform-delivering-materials-id___en",
    path: "/en/platform/delivering/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/materials/[id].vue")
  },
  {
    name: "platform-delivering-materials-id___tr",
    path: "/tr/platform/delivering/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/materials/[id].vue")
  },
  {
    name: "platform-delivering-materials-id___ar",
    path: "/platform/delivering/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/materials/[id].vue")
  },
  {
    name: "platform-delivering-stores-id___en",
    path: "/en/platform/delivering/stores/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/stores/[id].vue")
  },
  {
    name: "platform-delivering-stores-id___tr",
    path: "/tr/platform/delivering/stores/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/stores/[id].vue")
  },
  {
    name: "platform-delivering-stores-id___ar",
    path: "/platform/delivering/stores/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/stores/[id].vue")
  },
  {
    name: "platform-delivering-storeTypes-id___en",
    path: "/en/platform/delivering/storeTypes/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/storeTypes/[id].vue")
  },
  {
    name: "platform-delivering-storeTypes-id___tr",
    path: "/tr/platform/delivering/storeTypes/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/storeTypes/[id].vue")
  },
  {
    name: "platform-delivering-storeTypes-id___ar",
    path: "/platform/delivering/storeTypes/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/delivering/storeTypes/[id].vue")
  },
  {
    name: "platform___en",
    path: "/en/platform",
    component: () => import("/home/sila-sp/node_menu/pages/platform/index.vue")
  },
  {
    name: "platform___tr",
    path: "/tr/platform",
    component: () => import("/home/sila-sp/node_menu/pages/platform/index.vue")
  },
  {
    name: "platform___ar",
    path: "/platform",
    component: () => import("/home/sila-sp/node_menu/pages/platform/index.vue")
  },
  {
    name: "platform-material-id___en",
    path: "/en/platform/material/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/material/[id].vue")
  },
  {
    name: "platform-material-id___tr",
    path: "/tr/platform/material/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/material/[id].vue")
  },
  {
    name: "platform-material-id___ar",
    path: "/platform/material/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/material/[id].vue")
  },
  {
    name: "platform-materials-id___en",
    path: "/en/platform/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/materials/[id].vue")
  },
  {
    name: "platform-materials-id___tr",
    path: "/tr/platform/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/materials/[id].vue")
  },
  {
    name: "platform-materials-id___ar",
    path: "/platform/materials/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/materials/[id].vue")
  },
  {
    name: "platform-materials___en",
    path: "/en/platform/materials",
    component: () => import("/home/sila-sp/node_menu/pages/platform/materials/index.vue")
  },
  {
    name: "platform-materials___tr",
    path: "/tr/platform/materials",
    component: () => import("/home/sila-sp/node_menu/pages/platform/materials/index.vue")
  },
  {
    name: "platform-materials___ar",
    path: "/platform/materials",
    component: () => import("/home/sila-sp/node_menu/pages/platform/materials/index.vue")
  },
  {
    name: index1m5cHcuL2IMeta?.name,
    path: "/en/platform/my-account",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index.vue"),
    children: [
  {
    name: "platform-my-account-index-edit___en",
    path: "edit",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/edit.vue")
  },
  {
    name: "platform-my-account-index___en",
    path: "",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/index.vue")
  },
  {
    name: "platform-my-account-index-invite-friends___en",
    path: "invite-friends",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/invite-friends.vue")
  },
  {
    name: "platform-my-account-index-money-movements___en",
    path: "money-movements",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/money-movements.vue")
  },
  {
    name: "platform-my-account-index-notifications___en",
    path: "notifications",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/notifications.vue")
  },
  {
    name: "platform-my-account-index-orders-id___en",
    path: "orders/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/orders/[id].vue")
  },
  {
    name: "platform-my-account-index-orders___en",
    path: "orders",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/orders/index.vue")
  },
  {
    name: "platform-my-account-index-places___en",
    path: "places",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/places.vue")
  },
  {
    name: "platform-my-account-index-wishlist___en",
    path: "wishlist",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: index1m5cHcuL2IMeta?.name,
    path: "/tr/platform/my-account",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index.vue"),
    children: [
  {
    name: "platform-my-account-index-edit___tr",
    path: "edit",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/edit.vue")
  },
  {
    name: "platform-my-account-index___tr",
    path: "",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/index.vue")
  },
  {
    name: "platform-my-account-index-invite-friends___tr",
    path: "invite-friends",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/invite-friends.vue")
  },
  {
    name: "platform-my-account-index-money-movements___tr",
    path: "money-movements",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/money-movements.vue")
  },
  {
    name: "platform-my-account-index-notifications___tr",
    path: "notifications",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/notifications.vue")
  },
  {
    name: "platform-my-account-index-orders-id___tr",
    path: "orders/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/orders/[id].vue")
  },
  {
    name: "platform-my-account-index-orders___tr",
    path: "orders",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/orders/index.vue")
  },
  {
    name: "platform-my-account-index-places___tr",
    path: "places",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/places.vue")
  },
  {
    name: "platform-my-account-index-wishlist___tr",
    path: "wishlist",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: index1m5cHcuL2IMeta?.name,
    path: "/platform/my-account",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index.vue"),
    children: [
  {
    name: "platform-my-account-index-edit___ar",
    path: "edit",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/edit.vue")
  },
  {
    name: "platform-my-account-index___ar",
    path: "",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/index.vue")
  },
  {
    name: "platform-my-account-index-invite-friends___ar",
    path: "invite-friends",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/invite-friends.vue")
  },
  {
    name: "platform-my-account-index-money-movements___ar",
    path: "money-movements",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/money-movements.vue")
  },
  {
    name: "platform-my-account-index-notifications___ar",
    path: "notifications",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/notifications.vue")
  },
  {
    name: "platform-my-account-index-orders-id___ar",
    path: "orders/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/orders/[id].vue")
  },
  {
    name: "platform-my-account-index-orders___ar",
    path: "orders",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/orders/index.vue")
  },
  {
    name: "platform-my-account-index-places___ar",
    path: "places",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/places.vue")
  },
  {
    name: "platform-my-account-index-wishlist___ar",
    path: "wishlist",
    component: () => import("/home/sila-sp/node_menu/pages/platform/my-account/index/wishlist.vue")
  }
]
  },
  {
    name: "platform-offers___en",
    path: "/en/platform/offers",
    component: () => import("/home/sila-sp/node_menu/pages/platform/offers.vue")
  },
  {
    name: "platform-offers___tr",
    path: "/tr/platform/offers",
    component: () => import("/home/sila-sp/node_menu/pages/platform/offers.vue")
  },
  {
    name: "platform-offers___ar",
    path: "/platform/offers",
    component: () => import("/home/sila-sp/node_menu/pages/platform/offers.vue")
  },
  {
    name: "platform-privacy___en",
    path: "/en/platform/privacy",
    component: () => import("/home/sila-sp/node_menu/pages/platform/privacy.vue")
  },
  {
    name: "platform-privacy___tr",
    path: "/tr/platform/privacy",
    component: () => import("/home/sila-sp/node_menu/pages/platform/privacy.vue")
  },
  {
    name: "platform-privacy___ar",
    path: "/platform/privacy",
    component: () => import("/home/sila-sp/node_menu/pages/platform/privacy.vue")
  },
  {
    name: "platform-store-types___en",
    path: "/en/platform/store-types",
    component: () => import("/home/sila-sp/node_menu/pages/platform/store-types.vue")
  },
  {
    name: "platform-store-types___tr",
    path: "/tr/platform/store-types",
    component: () => import("/home/sila-sp/node_menu/pages/platform/store-types.vue")
  },
  {
    name: "platform-store-types___ar",
    path: "/platform/store-types",
    component: () => import("/home/sila-sp/node_menu/pages/platform/store-types.vue")
  },
  {
    name: "platform-stores-id___en",
    path: "/en/platform/stores/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/stores/[id].vue")
  },
  {
    name: "platform-stores-id___tr",
    path: "/tr/platform/stores/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/stores/[id].vue")
  },
  {
    name: "platform-stores-id___ar",
    path: "/platform/stores/:id()",
    component: () => import("/home/sila-sp/node_menu/pages/platform/stores/[id].vue")
  },
  {
    name: "platform-stores___en",
    path: "/en/platform/stores",
    component: () => import("/home/sila-sp/node_menu/pages/platform/stores/index.vue")
  },
  {
    name: "platform-stores___tr",
    path: "/tr/platform/stores",
    component: () => import("/home/sila-sp/node_menu/pages/platform/stores/index.vue")
  },
  {
    name: "platform-stores___ar",
    path: "/platform/stores",
    component: () => import("/home/sila-sp/node_menu/pages/platform/stores/index.vue")
  },
  {
    name: "platform-successful-purchase-cart___en",
    path: "/en/platform/successful-purchase-cart",
    component: () => import("/home/sila-sp/node_menu/pages/platform/successful-purchase-cart.vue")
  },
  {
    name: "platform-successful-purchase-cart___tr",
    path: "/tr/platform/successful-purchase-cart",
    component: () => import("/home/sila-sp/node_menu/pages/platform/successful-purchase-cart.vue")
  },
  {
    name: "platform-successful-purchase-cart___ar",
    path: "/platform/successful-purchase-cart",
    component: () => import("/home/sila-sp/node_menu/pages/platform/successful-purchase-cart.vue")
  },
  {
    name: "platform-terms___en",
    path: "/en/platform/terms",
    component: () => import("/home/sila-sp/node_menu/pages/platform/terms.vue")
  },
  {
    name: "platform-terms___tr",
    path: "/tr/platform/terms",
    component: () => import("/home/sila-sp/node_menu/pages/platform/terms.vue")
  },
  {
    name: "platform-terms___ar",
    path: "/platform/terms",
    component: () => import("/home/sila-sp/node_menu/pages/platform/terms.vue")
  }
]