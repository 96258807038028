import { FetchError } from 'ofetch'
import { useToast } from 'vue-toastification'
/* import i18n from '#i18n' */

export const useApiError = (error: FetchError<any> | null) => {
  if (error) {
    showError({
      statusCode: error.response?.status || error.statusCode || 500,
      message: error.data?.message || error.message || 'Error!'
    })
  }
}

export const useDomainHeader = () => {
  const { host, layout } = useDomainHost()

  if (process.env.NODE_ENV === 'development') {
    return layout === 'platform' ? 'dev.mevcut.co' : useDomain()
  } else {
    return host
  }
}

function queryLocale(query: any) {
  return {
    app_locale: useNuxtApp().$i18n?.locale?.value,
    ...(query || {})
  }
}

export const useBasicFetch: typeof useFetch = async (path, opts?) => {
  const toast = useToast()
  const { t } = useNuxtApp().$i18n
  let url = path as string
  if (!url.startsWith('/nuxt-api')) {
    url = useBaseUrl(path as string)
  }
  const response = await useFetch(url, {
    ...(opts || {}),

    query: queryLocale(opts?.query),

    headers: {
      ...(opts?.headers || {}),
      Accept: 'application/json',
      domain: useDomainHeader()
    }
  })

  if (response.error.value) {
    if (response.error.value.statusCode === 413) {
      toast.error(
        t('you_must_confirm_the', {
          name: t(
            `${response.error.value.data?.data?.message}`.toLocaleLowerCase()
          )
        })
      )
      navigateTo('/my-account')
    }
    throw response.error.value
  }

  return response
}
